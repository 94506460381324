type ButtonProps = {
	children:any,
	link?:string
}


const Button = (props:ButtonProps) => {
	const attributes = {} as any;

	if(props.link) {
		attributes.href = props.link;
	}


	return (
		<a
			{...attributes}
			className="uppercase py-2 px-4 rounded-lg bg-green-700 border-2 border-transparent text-white text-md mr-4 hover:bg-green-600">
			{props.children}
		</a>
	);
};

export default Button;
