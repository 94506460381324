import type { NextPage } from 'next';
import Image from 'next/image';
import Button from '../components/elements/button';
import imageNotFoundDark from '../../public/images/404-d.png';
import imageNotFound from '../../public/images/404.png';
import { getStaticPropsNicho } from 'ui';

const NotFound: NextPage = () => {
	return (
		<>
			<div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
				<span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
				<h1 className="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-800">
					¡Ya no está!
					<span className="text-5xl sm:text-7xl">Página no encontrada</span>
				</h1>
				<p className="text-sm sm:text-base text-gray-700 dark:text-white">
					Parece que estás intentado acceder a una página que no está disponible. Vuelve al inicio y
					comienza de nuevo.
				</p>
				<div className="flex mt-8">
					<Button link="/">Volver al inicio</Button>
				</div>
			</div>
			<div className="hidden sm:block dark:sm:hidden sm:w-1/3 lg:w-3/5 relative">
				<Image
					src={imageNotFound}
					className="max-w-xs md:max-w-sm m-auto"
					alt="Ayuda Finanzas - No hemos encontrado el coche que buscas"
				/>
			</div>
			<div className="hidden sm:hidden dark:sm:block sm:w-1/3 lg:w-3/5 relative">
				<Image
					src={imageNotFoundDark}
					className="max-w-xs md:max-w-sm m-auto"
					alt="Ayuda Finanzas - No hemos encontrado el coche que buscas"
				/>
			</div>
		</>
	);
};

export const getStaticProps = getStaticPropsNicho();
export default NotFound;
